<template>
  <a-spin :spinning="spinning">
    <div class="question-box" v-if="ready">
      <div class="flex flex-between top">
        <div class="t-left flex">
          <div class="tag">
            {{ getStatus(question_list[question_index]?.question_type_id) }}
          </div>
          <span class="ml-20 question-num"
            ><span class="blod">{{ question_index + 1 }}</span
            >/{{ question_list.length }}</span
          >
        </div>
        <div class="t-right flex">
          <span class="time"> 剩余时间 {{ getTime(exam_time) }}</span>
          <div class="sendbtn ml-30" @click="inster">交卷</div>
        </div>
      </div>
      <div class="title">
        {{
          question_index +
          1 +
          "、" +
          question_list[question_index]?.question_title
        }}
      </div>
      <div
        class="image-box"
        v-if="question_list[question_index].question_image"
      >
        <a-image
          style="margin-top: 20px"
          v-for="(ite, i) in question_list[question_index].question_image.split(
            ','
          )"
          :key="i"
          :src="ite"
        ></a-image>
      </div>
      <div class="question-check">
        <div v-for="(item, i) in option" :key="i">
          <div
            v-if="question_list[question_index][item.v]"
            class="check-e"
            :class="[
              i > 1 ? 'mt' : '',
              (i + 1) % 2 == 0 ? '' : 'mr',
              arr.includes(item.label) ? 'blue' : '',
            ]"
            @click="check(item.label)"
          >
            <div class="check-text">
              {{ [item.label] + "、" + question_list[question_index][item.v] }}
            </div>
          </div>
        </div>
      </div>
      <div class="btn-box flex">
        <div class="prev" @click="checkquestion(1)">上一题</div>
        <div class="next" @click="checkquestion">下一题</div>
      </div>
    </div>
  </a-spin>
</template>
<script setup>
import { getExamQuestionList, updateExamRecordScoreData } from "@/api/main.js";
import { useStore } from "vuex";
import { ref, reactive, onUnmounted, onMounted } from "vue";
import url from "@/utils/url.js";
import { encode, decode } from "js-base64";
import { useRoute, useRouter } from "vue-router";
import { message, Modal, notification } from "ant-design-vue";
const store = useStore();
const route = useRoute();
const spinning = ref(true);
let query = decode(route.query?.t).split(",");
const exam_time = ref(Number(query[2]));
const question_list = ref([]);
const ready = ref(false);
console.log(query);
getExamQuestionList({
  user_id: store.state.user.user_id,
  exam_id: query[1],
  record_id: query[0],
}).then((res) => {
  if (res.code == 200) {
    console.log(res.data);
    question_list.value = res.data.question;
  } else {
    message.error(res.msg);
  }
  spinning.value = false;
  ready.value = true;
});

const question_index = ref(0);
const option = [
  {
    v: "question_option_1",
    label: "A",
  },
  {
    v: "question_option_2",
    label: "B",
  },
  {
    v: "question_option_3",
    label: "C",
  },
  {
    v: "question_option_4",
    label: "D",
  },
  {
    v: "question_option_5",
    label: "E",
  },
  {
    v: "question_option_6",
    label: "F",
  },
];
const getStatus = (v) => {
  if (v == 1) {
    return "单选题";
  } else if (v == 2) {
    return "多选题";
  } else {
    return "判断题";
  }
};
let question_answer = [];
const arr = ref([]);
const check = (id) => {
  if (exam_time.value <= 0) {
    return message.info("考试用时已超时！");
  }
  let q_id = question_list.value[question_index.value].question_id;
  let index = question_answer.findIndex((v) => v.question_id == q_id);
  if (question_list.value[question_index.value].question_type_id != 2) {
    arr.value = [id];
    if (index == -1) {
      question_answer.push({ question_id: q_id, question_answer: id });
    } else {
      question_answer[index] = { question_id: q_id, question_answer: id };
    }
  } else {
    if (arr.value.includes(id)) {
      arr.value = arr.value.filter((item) => item != id);
    } else {
      arr.value = [...arr.value, id];
    }
    if (index == -1) {
      question_answer.push({
        question_id: q_id,
        question_answer: arr.value.join(","),
      });
    } else {
      question_answer[index] = {
        question_id: q_id,
        question_answer: arr.value.join(","),
      };
    }
  }
};
const checkquestion = (v) => {
  if (exam_time.value <= 0) {
    return message.info("考试用时已超时！");
  }
  if (v == 1) {
    if (question_index.value < 1) {
      return;
    }
    question_index.value -= 1;
  } else {
    if (question_index.value + 1 >= question_list.value.length) {
      return;
    }
    if (arr.value.length == 0) {
      message.info("请选择答案");
      return;
    }
    question_index.value += 1;
  }
  let q_id = question_list.value[question_index.value].question_id;
  let index = question_answer.findIndex((v) => v.question_id == q_id);
  if (index != -1) {
    arr.value = question_answer[index].question_answer.split(",");
  } else {
    arr.value = [];
  }
};
const inster = () => {
  Modal.info({
    title: "温馨提示",
    content: "请您检查无误后确认交卷",
    okText: "交卷",
    cancelText: "取消",
    centered: true,
    onOk() {
      updateExamRecordScoreData({
        user_id: store.state.user.user_id,
        exam_id: query[1],
        record_id: query[0],
        record_question: JSON.stringify(question_answer),
      })
        .then((res) => {
          if (res.code == 200) {
            notification.success({
              message: "交卷成功",
              description: "正在获取成绩",
              duration: 1,
            });

            setTimeout(() => {
              url.replaceTo("/exam/scoredata", { t: encode(`${query[0]}`) });
            }, 1000);
          } else {
            message.error(res.msg);
          }
        })
        .catch((er) => {
          message.error("请刷新网络后重试");
        });
    },
  });
};

const getTime = (value) => {
  let secondTime = parseInt(value); // 秒
  let minuteTime = 0; // 分
  let hourTime = 0; // 时
  if (secondTime > 60) {
    minuteTime = parseInt(secondTime / 60);
    secondTime = parseInt(secondTime % 60);
    if (minuteTime > 60) {
      hourTime = parseInt(minuteTime / 60);
      minuteTime = parseInt(minuteTime % 60);
    }
  }
  secondTime = secondTime < 10 ? "0" + secondTime : secondTime;
  var result = "" + secondTime + "";
  if (minuteTime > 0) {
    minuteTime = minuteTime < 10 ? "0" + minuteTime : minuteTime;
    result = "" + minuteTime + ":" + result;
  } else {
    result = "" + "00" + ":" + result;
  }

  if (hourTime > 0) {
    hourTime = hourTime < 10 ? "0" + hourTime : hourTime;
    result = "" + hourTime + ":" + result;
  } else {
    result = "" + "00" + ":" + result;
  }
  console.log("result", result);
  return result;
};
let timer;
onMounted(() => {
  timer = setInterval(() => {
    if (exam_time.value >= 1) {
      exam_time.value -= 1;
      console.log(1);
    } else {
      clearInterval(timer);
    }
  }, 1000);
});
onUnmounted(() => {
  if (timer) {
    clearInterval(timer);
    timer = null;
  }
});
</script>
<style lang="less" scoped>
.image-box {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.question-box {
  width: 1200px;
  margin: 0 auto;
  .top {
    height: 92px;
    border-bottom: 1px solid #dfdfdf;
    .tag {
      font-size: 14px;
      text-align: center;
      line-height: 24px;
      color: #1276cb;
      width: 71px;
      height: 24px;
      border-radius: 12px;
      border: solid 1px #1276cb;
    }
    .question-num {
      font-size: 20px;
      .blod {
        color: #1276cb;
        font-weight: 600;
      }
    }
    .t-right {
      .sendbtn {
        width: 100px;
        height: 40px;
        font-size: 18px;
        color: #ffffff;
        background-color: #1276cb;
        border-radius: 10px;
        text-align: center;
        line-height: 40px;
        margin-left: 30px;
      }
      .time {
        font-size: 18px;

        color: #212531;
        opacity: 0.7;
      }
    }
  }
  .title {
    width: 1160px;
    margin: 0 auto;
    font-size: 22px;
    font-weight: bold;
    font-stretch: normal;
    line-height: 34px;
    letter-spacing: 0px;
    margin-top: 20px;
    color: #212531;
  }
  .question-check {
    display: flex;
    flex-wrap: wrap;
    width: 900px;
    margin: 96px auto 193px auto;
    .mt {
      margin-top: 61px;
    }
    .mr {
      margin-right: 100px;
    }
    .blue {
      background-color: #1276cb !important;
      color: white;
    }
    .check-e {
      transition: 0.2s all;
      width: 400px;
      min-height: 66px;
      text-align: center;
      background-color: #f3f5f8;
      border-radius: 33px;
      overflow: hidden;
      cursor: pointer;
      .check-text {
        margin: 22px;
        font-size: 22px;
      }
    }
  }
  .btn-box {
    width: 839px;
    margin: 0 auto;
    display: flex;
    margin-bottom: 110px;
    .prev {
      font-size: 20px;
      text-align: center;
      line-height: 61px;
      border-radius: 30px;
      cursor: pointer;
      width: 398px;
      margin-right: 39px;
      height: 59px;
      background: white;
      border: solid 1px #e0e0e0;
      color: #212531;
    }
    .next {
      font-size: 20px;
      text-align: center;
      line-height: 61px;
      cursor: pointer;
      width: 400px;
      height: 61px;
      border-radius: 30px;
      background-color: #1276cb;
      color: white;
    }
  }
}
</style>